<template>
  <div id="bg">
    <div class="search">
      <div class="search-box">
        <el-date-picker v-model="searchDate" type="datetimerange" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" value-format="YYYY-MM-DD HH:mm:ss"></el-date-picker>
      </div>
      <div class="search-box">
        用户:<el-input type="text" class="width_150" placeholder="请输入用户ID"  v-model="search.tip_uid"></el-input>
        帖子:<el-input type="text" class="width_150" placeholder="请输入帖子ID"  v-model="search.post_id"></el-input>
        楼主:<el-input type="text" class="width_150" placeholder="请输入楼主ID"  v-model="search.target_uid"></el-input>
        <el-button type="primary" size="medium" @click="getList()">查询</el-button>
      </div>
    </div>
    <el-table :data="tableData" @selection-change="selectionChange" :header-cell-style="{background:'#F7F8FA'}">
      <el-table-column type="selection" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="id" label="ID" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="book_id" label="分类" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
          <span v-if="scope.row.book_id <= 20000000">帖子</span>
          <span v-if="scope.row.book_id > 20000000 && scope.row.book_id <= 40000000">漫画</span>
          <span v-if="scope.row.book_id > 40000000 && scope.row.book_id <= 80000000">美图</span>
          <span v-if="scope.row.book_id > 80000000">小说</span>:
        </template>
      </el-table-column>
      <el-table-column prop="target_id" label="评论ID" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="report_uid" label="举报人" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="target_uid" label="被举报人" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="post_id" label="帖子ID" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="price" label="打赏金额" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="reason" label="举报理由" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="discuss" label="评论内容" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="add_time" label="举报时间" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="status" label="状态" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
          <span v-if="scope.row.status == 0" style="color: red">处理中</span>
          <span v-if="scope.row.status == 1" style="color: green">已处理</span>
        </template>
      </el-table-column>
      <el-table-column prop="handle_type" label="处理方式" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
          <div v-if="scope.row.status == 0">
            <el-button @click="showDialog(scope.row)">处理</el-button>
          </div>
          <div v-else>
            <span v-if="scope.row.handle_type == 0" >不处理</span>
            <span v-if="scope.row.handle_type == 1" >屏蔽</span>
            <span v-if="scope.row.handle_type == 2" >禁言</span>
            <span v-if="scope.row.handle_type == 3" >拉黑</span>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <page :pager="pager" @query="getList()" />

    <el-dialog title="举报处理" v-model="dialogVisible" width="600px" height="500px" top="5vh" :close-on-click-modal="false" >
      <el-row>
        <el-col :span="4" align="right">评论内容</el-col>
        <el-col :span="20">
          <el-input v-model="dialogArray.content" disabled></el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4" align="right">举报理由</el-col>
        <el-col :span="20">
          <el-input v-model="dialogArray.reason" disabled></el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4" align="right">处理方式</el-col>
        <el-col :span="20">
          <el-radio v-model="dialogArray.handle_type" :label=0 >不处理</el-radio>
          <el-radio v-model="dialogArray.handle_type" :label=1 >屏蔽</el-radio>
          <el-radio v-model="dialogArray.handle_type" :label=2 >禁言</el-radio>
          <el-radio v-model="dialogArray.handle_type" :label=3 >拉黑</el-radio>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6" align="right"></el-col>
        <el-col :span="18">
          <el-button @click="dialogVisible=false">关闭</el-button>
          <el-button @click="save()">处理</el-button>
        </el-col>
      </el-row>
      <div style="height: 20px;"></div>
    </el-dialog>


  </div>
</template>

<script>
import page from "@/components/page";
import httpClient from "@/config/httpClient";
export default {
  name: "community_report",
  components: {
    page,
  },
  data(){
    return {
      searchDate:[],
      pager:{page:1,rows:50,total:0},
      search:{
        type:-1,
        target_uid:null,
        post_id:null,
        tip_uid:null,
      },
      tableData:[],
      dialogVisible:false,
      dialogArray:{
        content:"",
        reason:"",
        id:0,
        handle_type:0,
        row:null,
      }
    }
  },
  mounted() {
    this.getList();
  },
  methods:{
    save(){
      console.log(this.dialogArray)
      this.$common.showLoading()
      httpClient("BatchReportHandle").post({
        "ids":this.dialogArray.id,
        "handle_type":this.dialogArray.handle_type,
      }).then((res)=>{
        this.$common.hideLoading()
        if(res.code == 0){
          this.dialogArray.row.status = 1
          this.dialogArray.row.handle_type = this.dialogArray.handle_type
        }else{
          this.$message({message:res.msg,type:"error"})
        }
      },(err)=>{
        this.$common.hideLoading()
        console.log(err)
      })
    },
    showDialog(row){
      console.log(row)
      this.dialogVisible = true
      this.dialogArray.content = row.discuss
      this.dialogArray.reason = row.reason
      this.dialogArray.id = row.id
      this.dialogArray.handle_type = 0
      this.dialogArray.row = row
    },
    getList(){
      var params = {
        page:this.pager.page,
        row:this.pager.rows,
        post_id:this.search.post_id,
        target_uid:this.search.target_uid,
        tip_uid:this.search.tip_uid,
        begin_time:this.searchDate && this.searchDate.length > 1?this.searchDate[0]:"",
        end_time:this.searchDate && this.searchDate.length > 1?this.searchDate[1]:"",
      }
      this.$common.showLoading()
      httpClient("GetReportList").post(params).then((res)=>{
        this.$common.hideLoading()
        if (res.code==0){
          this.tableData = res.data.list
          if (this.pager.page == 1 ){
            this.pager.total = res.data.total
          }
        }else{
          this.$message({message:res.msg,type:"error"})
        }
      },(err)=>{
        this.$common.hideLoading()
        console.log(err)
      })
    },
  }
}
</script>

<style scoped>
.el-row{
  margin-bottom: 5px;
}
.el-row .el-col{
  padding-right: 5px;
}
</style>